import { NgModule } from '@angular/core';


// import { CarouselModule } from 'ngx-owl-carousel-o';
// import { NgOtpInputModule } from  'ng-otp-input';

// import { ExtrapagesRoutingModule } from './extrapages-routing.module';

import { Page404Component } from './page404/page404.component';


@NgModule({
  // tslint:disable-next-line: max-line-length
  declarations: [ Page404Component, 
   
   
  
    // NgOtpInputModule
  ]
})
export class ExtrapagesModule { }
