<div class="card">
    <div class="card-body">
        <div class="row align-items-center">
            <div class="col-md-4">
                <h4 class="card-title mb-0">Accordion</h4>
            </div>
            <div class="col-md-3 ms-auto">
                <form class="app-search d-none d-xl-block">
                    <div class="position-relative">
                        <input type="text" class="form-control" placeholder="{{'HEADER.SEARCH' | translate}}">
                        <span class="bx bx-search-alt"></span>
                    </div>
                </form>
            </div>
        </div>
        <div class="row">
            <div class="col-xl-12">
                <div class="mt-4">

                    <ngb-accordion #acc="ngbAccordion" activeIds="static-1" [closeOthers]="true">
                        <ngb-panel id="static-1">
                            <ng-template ngbPanelTitle class="text-black">
                                <div class="d-grid">
                               <div class="p-2"> <span class="badge badge-soft-secondary">Todo</span></div>
                               <div class="p-2"><div class="status-item mx-5">
                                    <span class="status-label">Count:</span> 6
                                </div></div>
                                <div class="p-2"><div class="status-item">
                                    <span class="status-label">Sum:</span> 1714
                                </div></div>
                                </div>
                            </ng-template>
                            <ng-template ngbPanelContent>
                                <div class="table-container">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th>Name</th>
                                                <th>ID</th>
                                                <th>Service Requested By</th>
                                                <th>Description</th>
                                                <th>Requested By</th>
                                                <th>Status</th>
                                                <th>Priority</th>
                                                <th>Phone Number</th>
                                                <th>Paid Status</th>
                                                <th>Amount Due</th>
                                                <th>Weight</th>
                                                <th>Ability Levels</th>
                                                <th>Boot Shell Length</th>
                                                <th>Gear</th>
                                                <th>Gear Pics</th>
                                                <th>Location</th>
                                                <th>Created</th>
                                                <th>Due Date</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let item of tableData">
                                                <td><button type="button" class="btn" (click)="extraLarge(exlargeModal)">
                                                    <i class="bx bx-edit-alt"></i>
                                                </button></td>
                                                <td>{{ item.name }}</td>
                                                <td>{{ item.Id }}</td>
                                                <td>{{ item.ServiceRequestedBy }}</td>
                                                <td>{{ item.Description }}</td>
                                                <td>{{ item.RequestedBy }}</td>
                                                <td>{{ item.Status }}</td>
                                                <td>{{ item.Priority }}</td>
                                                <td>{{ item.Phone }}</td>
                                                <td>{{ item.PaidStatus }}</td>
                                                <td>{{ item.AmountDue }}</td>
                                                <td>{{ item.Weight }}</td>
                                                <td>{{ item.AbilityLevels }}</td>
                                                <td>{{ item.BootShellLength }}</td>
                                                <td>{{ item.Gear }}</td>
                                                <td>{{ item.GearPics }}</td>
                                                <td>{{ item.Location }}</td>
                                                <td>{{ item.Created }}</td>
                                                <td>{{ item.DueDate }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </ng-template>
                        </ngb-panel>
                        <ngb-panel id="static-2">
                            <ng-template ngbPanelTitle>
                                <div class="d-grid">
                                <div class="p-2"><span class="badge badge-soft-warning"> In Progress</span></div>
                                <div class="p-2"><div class="status-item mx-5">
                                    <span class="status-label">Count:</span> 8
                                </div></div>
                                <div class="p-2"> <div class="status-item">
                                    <span class="status-label">Sum:</span> 1210
                                </div></div>
                                </div>
                            </ng-template>
                            <ng-template ngbPanelContent>
                                <div class="table-container">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th>Name</th>
                                                <th>ID</th>
                                                <th>Service Requested By</th>
                                                <th>Description</th>
                                                <th>Requested By</th>
                                                <th>Status</th>
                                                <th>Priority</th>
                                                <th>Phone Number</th>
                                                <th>Paid Status</th>
                                                <th>Amount Due</th>
                                                <th>Weight</th>
                                                <th>Ability Levels</th>
                                                <th>Boot Shell Length</th>
                                                <th>Gear</th>
                                                <th>Gear Pics</th>
                                                <th>Location</th>
                                                <th>Created</th>
                                                <th>Due Date</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let item of tableData">
                                                <td><button type="button" class="btn" (click)="extraLarge(exlargeModal)">
                                                    <i class="bx bx-edit-alt"></i>
                                                </button></td>
                                                <td>{{ item.name }}</td>
                                                <td>{{ item.Id }}</td>
                                                <td>{{ item.ServiceRequestedBy }}</td>
                                                <td>{{ item.Description }}</td>
                                                <td>{{ item.RequestedBy }}</td>
                                                <td>{{ item.Status }}</td>
                                                <td>{{ item.Priority }}</td>
                                                <td>{{ item.Phone }}</td>
                                                <td>{{ item.PaidStatus }}</td>
                                                <td>{{ item.AmountDue }}</td>
                                                <td>{{ item.Weight }}</td>
                                                <td>{{ item.AbilityLevels }}</td>
                                                <td>{{ item.BootShellLength }}</td>
                                                <td>{{ item.Gear }}</td>
                                                <td>{{ item.GearPics }}</td>
                                                <td>{{ item.Location }}</td>
                                                <td>{{ item.Created }}</td>
                                                <td>{{ item.DueDate }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </ng-template>
                        </ngb-panel>
                        <ngb-panel id="static-3">
                            <ng-template ngbPanelTitle>
                                <div class="d-grid">
                               <div class="p-2" ><span class="badge badge-soft-danger"> Issue / Contact Customer</span></div>
                               <div class="p-2" ><div class="status-item mx-5">
                                    <span class="status-label">Count:</span> 14
                                </div></div>
                                <div class="p-2" > <div class="status-item">
                                    <span class="status-label">Sum:</span> 800
                                </div></div>
                                </div>
                            </ng-template>
                            <ng-template ngbPanelContent>
                                <div class="table-container">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th>Name</th>
                                                <th>ID</th>
                                                <th>Service Requested By</th>
                                                <th>Description</th>
                                                <th>Requested By</th>
                                                <th>Status</th>
                                                <th>Priority</th>
                                                <th>Phone Number</th>
                                                <th>Paid Status</th>
                                                <th>Amount Due</th>
                                                <th>Weight</th>
                                                <th>Ability Levels</th>
                                                <th>Boot Shell Length</th>
                                                <th>Gear</th>
                                                <th>Gear Pics</th>
                                                <th>Location</th>
                                                <th>Created</th>
                                                <th>Due Date</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let item of tableData">
                                                <td><button type="button" class="btn" (click)="extraLarge(exlargeModal)">
                                                    <i class="bx bx-edit-alt"></i>
                                                </button></td>
                                                <td>{{ item.name }}</td>
                                                <td>{{ item.Id }}</td>
                                                <td>{{ item.ServiceRequestedBy }}</td>
                                                <td>{{ item.Description }}</td>
                                                <td>{{ item.RequestedBy }}</td>
                                                <td>{{ item.Status }}</td>
                                                <td>{{ item.Priority }}</td>
                                                <td>{{ item.Phone }}</td>
                                                <td>{{ item.PaidStatus }}</td>
                                                <td>{{ item.AmountDue }}</td>
                                                <td>{{ item.Weight }}</td>
                                                <td>{{ item.AbilityLevels }}</td>
                                                <td>{{ item.BootShellLength }}</td>
                                                <td>{{ item.Gear }}</td>
                                                <td>{{ item.GearPics }}</td>
                                                <td>{{ item.Location }}</td>
                                                <td>{{ item.Created }}</td>
                                                <td>{{ item.DueDate }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </ng-template>
                        </ngb-panel>
                        <ngb-panel id="static-4">
                            <ng-template ngbPanelTitle>
                                <div class="d-grid">
                                <div class="p-2" > <span class="badge badge-soft-success">Completed</span></div>
                                <div class="p-2" > <div class="status-item mx-5">
                                    <span class="status-label">Count:</span> 4
                                </div></div>
                                <div class="p-2" ><div class="status-item">
                                    <span class="status-label">Sum:</span> 300
                                </div></div>
                            </div>
                            </ng-template>
                            <ng-template ngbPanelContent>
                                <div class="table-container">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th>Name</th>
                                                <th>ID</th>
                                                <th>Service Requested By</th>
                                                <th>Description</th>
                                                <th>Requested By</th>
                                                <th>Status</th>
                                                <th>Priority</th>
                                                <th>Phone Number</th>
                                                <th>Paid Status</th>
                                                <th>Amount Due</th>
                                                <th>Weight</th>
                                                <th>Ability Levels</th>
                                                <th>Boot Shell Length</th>
                                                <th>Gear</th>
                                                <th>Gear Pics</th>
                                                <th>Location</th>
                                                <th>Created</th>
                                                <th>Due Date</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let item of tableData">
                                                <td><button type="button" class="btn" (click)="extraLarge(exlargeModal)">
                                                    <i class="bx bx bx-pencil"></i>
                                                </button></td>
                                                <td>{{ item.name }}</td>
                                                <td>{{ item.Id }}</td>
                                                <td>{{ item.ServiceRequestedBy }}</td>
                                                <td>{{ item.Description }}</td>
                                                <td>{{ item.RequestedBy }}</td>
                                                <td>{{ item.Status }}</td>
                                                <td>{{ item.Priority }}</td>
                                                <td>{{ item.Phone }}</td>
                                                <td>{{ item.PaidStatus }}</td>
                                                <td>{{ item.AmountDue }}</td>
                                                <td>{{ item.Weight }}</td>
                                                <td>{{ item.AbilityLevels }}</td>
                                                <td>{{ item.BootShellLength }}</td>
                                                <td>{{ item.Gear }}</td>
                                                <td>{{ item.GearPics }}</td>
                                                <td>{{ item.Location }}</td>
                                                <td>{{ item.Created }}</td>
                                                <td>{{ item.DueDate }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </ng-template>
                        </ngb-panel>
                        <ngb-panel id="static-5">
                            <ng-template ngbPanelTitle>
                                <div class="d-grid">
                                <div class="p-2"><span class="badge badge-soft-info">Picked Up</span></div>
                                <div class="p-2"><div class="status-item mx-5">
                                    <span class="status-label">Count:</span> 6
                                </div></div>
                                <div class="p-2"><div class="status-item">
                                    <span class="status-label">Sum:</span> 1714
                                </div>
                            </div>
                            </div>
                            </ng-template>
                            <ng-template ngbPanelContent>
                                <div class="table-container">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th>Name</th>
                                                <th>ID</th>
                                                <th>Service Requested By</th>
                                                <th>Description</th>
                                                <th>Requested By</th>
                                                <th>Status</th>
                                                <th>Priority</th>
                                                <th>Phone Number</th>
                                                <th>Paid Status</th>
                                                <th>Amount Due</th>
                                                <th>Weight</th>
                                                <th>Ability Levels</th>
                                                <th>Boot Shell Length</th>
                                                <th>Gear</th>
                                                <th>Gear Pics</th>
                                                <th>Location</th>
                                                <th>Created</th>
                                                <th>Due Date</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let item of tableData">
                                                <td><button type="button" class="btn" (click)="extraLarge(exlargeModal)">
                                                    <i class="bx bx-edit-alt"></i>
                                                </button></td>
                                                <td>{{ item.name }}</td>
                                                <td>{{ item.Id }}</td>
                                                <td>{{ item.ServiceRequestedBy }}</td>
                                                <td>{{ item.Description }}</td>
                                                <td>{{ item.RequestedBy }}</td>
                                                <td>{{ item.Status }}</td>
                                                <td>{{ item.Priority }}</td>
                                                <td>{{ item.Phone }}</td>
                                                <td>{{ item.PaidStatus }}</td>
                                                <td>{{ item.AmountDue }}</td>
                                                <td>{{ item.Weight }}</td>
                                                <td>{{ item.AbilityLevels }}</td>
                                                <td>{{ item.BootShellLength }}</td>
                                                <td>{{ item.Gear }}</td>
                                                <td>{{ item.GearPics }}</td>
                                                <td>{{ item.Location }}</td>
                                                <td>{{ item.Created }}</td>
                                                <td>{{ item.DueDate }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </ng-template>
                        </ngb-panel>
                    </ngb-accordion>
                    <!-- end accordion -->
                </div>
            </div>
            <!-- end col -->

            <!-- end col -->
        </div>
        <!-- end row -->
    </div>
</div>


<ng-template #exlargeModal let-modal>
    <div class="modal-header">
        <h5 class="modal-title" id="myExtraLargeModalLabel">Extra large modal</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
            (click)="modal.dismiss('Close click')"></button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-6">
                <div class="card">
                    <div class="card-title">Personal Info</div>
                    <div class="card-body">                       
                        <div class="mb-3 row">
                            <label for="example-text-input" class="col-md-4 col-form-label">Id</label>
                            <div class="col-md-8">
                                <input class="form-control" type="text" value="" id="example-text-input">
                            </div>
                        </div>
                        <div class="mb-3 row">
                            <label for="example-text-input" class="col-md-4 col-form-label">Name</label>
                            <div class="col-md-8">
                                <input class="form-control" type="text" value="" id="example-text-input">
                            </div>
                        </div>
                        <div class="mb-3 row">
                            <label for="example-text-input" class="col-md-4 col-form-label">Phone Number</label>
                            <div class="col-md-8">
                                <input class="form-control" type="text" value="" id="example-text-input">
                            </div>
                        </div>
                        <div class="mb-3 row">
                            <label class="col-md-4 col-form-label">Weight</label>
                            <div class="col-md-8">
                                <select class="form-select">
                                    <option></option>
                                    <option>
                                        <22lbs </option>
                                    <option>30-45lbs</option>
                                </select>
                            </div>
                        </div>
                        <div class="mb-3 row">
                            <label for="message" class="col-md-4 col-form-label">Description</label>
                            <div class="col-md-8">
                                <textarea class="form-control" formControlName="msg"></textarea>
                            </div>
                        </div>
                        <div class="mb-3 row">
                            <label for="example-text-input" class="col-md-4 col-form-label">Service Requested
                                By</label>
                            <div class="col-md-8">
                                <input class="form-control" type="text" value="" id="example-text-input">
                            </div>
                        </div>
                        <div class="mb-3 row">
                            <label class="col-md-4 col-form-label">Requested By</label>
                            <div class="col-md-8">
                                <select class="form-select">
                                    <option></option>

                                    <option><span class="badge badge-soft-secondary">Monte</span></option>
                                    <option>Dallon</option>
                                    <option>Tim</option>
                                    <option>zoe</option>
                                </select>
                            </div>
                        </div>
                        <div class="mb-3 row">
                            <label class="col-md-4 col-form-label">Priority</label>
                            <div class="col-md-8">
                                <select class="form-select">
                                    <option></option>
                                    <option>High</option>
                                    <option>Medium</option>
                                    <option>Low</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">

                <div class="card">
                    <div class="card-title">Service Order Details</div>
                    <div class="card-body">
                        <div class="mb-3 row">
                            <label class="col-md-4 col-form-label">Ability Levels</label>
                            <div class="col-md-8">
                                <select class="form-select">
                                    <option></option>
                                    <option><span class="badge badge-soft-secondary">Todo</span></option>
                                    <option>Beginner</option>
                                    <option>Intermediate</option>
                                    <option>Advanced</option>
                                </select>
                            </div>
                        </div>
                        <div class="mb-3 row">
                            <label for="example-text-input" class="col-md-4 col-form-label">Boot Shell Length</label>
                            <div class="col-md-8">
                                <input class="form-control" type="text" value="" id="example-text-input">
                            </div>
                        </div>
                        <div class="mb-3 row">
                            <label class="col-md-4 col-form-label">Paid Status</label>
                            <div class="col-md-8">
                                <select class="form-select">
                                    <option></option>
                                    <option>Paid</option>
                                    <option>Needs Payment</option>
                                </select>
                            </div>
                        </div>
                        <div class="mb-3 row">
                            <label for="example-text-input" class="col-md-4 col-form-label">Amount Due</label>
                            <div class="col-md-8">
                                <input class="form-control" type="text" value="" id="example-text-input">
                            </div>
                        </div>
                        <div class="mb-3 row">
                            <label class="col-md-4 col-form-label">Status</label>
                            <div class="col-md-8">
                                <select class="form-select">
                                    <option></option>
                                    <option>Todo
                                    </option>
                                    <option>In Progress</option>
                                    <option>Completed</option>
                                    <option>Picked Up</option>
                                </select>
                            </div>
                        </div>
                        <div class="mb-3 row">
                            <label for="example-text-input" class="col-md-4 col-form-label">Completed By</label>
                            <div class="col-md-8">
                                <input class="form-control" type="text" value="" id="example-text-input">
                            </div>
                        </div>
                        <div class="mb-3 row">
                            <label for="message" class="col-md-4 col-form-label">Gear</label>
                            <div class="col-md-8">
                                <textarea class="form-control" formControlName="msg"></textarea>
                            </div>
                        </div>
                        <div class="mb-3 row">
                            <label for="example-datetime-local-input" class="col-md-4 col-form-label">Created
                                Date</label>
                            <div class="col-md-8">
                                <input class="form-control" type="datetime-local" value="2019-08-19T13:45:00"
                                    id="example-datetime-local-input">
                            </div>
                        </div>
                        <div class="mb-3 row">
                            <label for="example-datetime-local-input" class="col-md-4 col-form-label">Due Date</label>
                            <div class="col-md-8">
                                <input class="form-control" type="datetime-local" value="2019-08-19T13:45:00"
                                    id="example-datetime-local-input">
                            </div>
                        </div>
                    </div>
                </div>
               
            </div>
        </div>

            <!-- <div class="col-md-6">
                <div class="card">
                    <div class="card-body">
                        
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="card">
                    <div class="card-body">


                        
                    </div>
                </div>
            </div> -->
       
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light" data-bs-dismiss="modal"
            (click)="modal.close('Close click')">Close</button>
        <button type="button" class="btn btn-primary">Save changes</button>
    </div>
</ng-template><!-- /.modal -->