import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
    {
        id: 1,
        label: 'MENUITEMS.MENU.TEXT',
        isTitle: true
    },
    {
        id: 2,
        label: 'MENUITEMS.DASHBOARDS.TEXT',
        icon: 'bx-home-circle',
        link: '',
        // subItems: [
        //     // {
        //     //     id: 3,
        //     //     label: 'MENUITEMS.DASHBOARDS.LIST.DEFAULT',
        //     //     link: '/dashboard',
        //     //     parentId: 2
        //     // },
        //     // {
        //     //     id: 4,
        //     //     label: 'MENUITEMS.DASHBOARDS.LIST.SAAS',
        //     //     link: '/dashboards/saas',
        //     //     parentId: 2
        //     // },
        //     // {
        //     //     id: 5,
        //     //     label: 'MENUITEMS.DASHBOARDS.LIST.CRYPTO',
        //     //     link: '/dashboards/crypto',
        //     //     parentId: 2
        //     // },
        //     // {
        //     //     id: 6,
        //     //     label: 'MENUITEMS.DASHBOARDS.LIST.BLOG',
        //     //     link: '/dashboards/blog',
        //     //     parentId: 2
        //     // },
        //     // {
        //     //     id: 7,
        //     //     label: 'MENUITEMS.DASHBOARDS.LIST.JOBS',
        //     //     link: '/dashboards/jobs',
        //     //     parentId: 2,
        //     //     badge: {
        //     //         variant: 'success',
        //     //         text: 'MENUITEMS.FILEMANAGER.BADGE',
        //     //     },
        //     // },
        // ]
    },
    {
        id: 200,
        label: 'Intake',
        icon: 'bx-home-circle',
        link: '/intake',
    },
    {
        id: 203,
        label: 'Service',
        icon: 'bx-home-circle',
        subItems: [
            {
                id: 204,
                label: 'Grid View',
                link: '/bluebook',
            },
            {
                id: 205,
                label: 'Service Ticket',
                link: '/serviceticket',
            }
        ],
    },
     {
        id: 201,
        label: 'Bluebook',
        icon: 'bx-home-circle',
        link: '/',
     },
    // {
    //     id: 8,
    //     isLayout: true
    // },
    // {
    //     id: 9,
    //     label: 'MENUITEMS.APPS.TEXT',
    //     isTitle: true
    // },
    // {
    //     id: 10,
    //     label: 'MENUITEMS.CALENDAR.TEXT',
    //     icon: 'bx-calendar',
    //     link: '/calendar',
    // },
    // {
    //     id: 11,
    //     label: 'MENUITEMS.CHAT.TEXT',
    //     icon: 'bx-chat',
    //     link: '/chat',
        
    // },
    // {
    //     id: 12,
    //     label: 'MENUITEMS.FILEMANAGER.TEXT',
    //     icon: 'bx-file',
    //     link: '/filemanager',
    // },
    // {
    //     id: 13,
    //     label: 'MENUITEMS.ECOMMERCE.TEXT',
    //     icon: 'bx-store',
    //     subItems: [
    //         {
    //             id: 14,
    //             label: 'MENUITEMS.ECOMMERCE.LIST.PRODUCTS',
    //             link: '/ecommerce/products',
    //             parentId: 13
    //         },
    //         {
    //             id: 15,
    //             label: 'MENUITEMS.ECOMMERCE.LIST.PRODUCTDETAIL',
    //             link: '/ecommerce/product-detail/1',
    //             parentId: 13
    //         },
    //         {
    //             id: 16,
    //             label: 'MENUITEMS.ECOMMERCE.LIST.ORDERS',
    //             link: '/ecommerce/orders',
    //             parentId: 13
    //         },
    //         {
    //             id: 17,
    //             label: 'MENUITEMS.ECOMMERCE.LIST.CUSTOMERS',
    //             link: '/ecommerce/customers',
    //             parentId: 13
    //         },
    //         {
    //             id: 18,
    //             label: 'MENUITEMS.ECOMMERCE.LIST.CART',
    //             link: '/ecommerce/cart',
    //             parentId: 13
    //         },
    //         {
    //             id: 19,
    //             label: 'MENUITEMS.ECOMMERCE.LIST.CHECKOUT',
    //             link: '/ecommerce/checkout',
    //             parentId: 13
    //         },
    //         {
    //             id: 20,
    //             label: 'MENUITEMS.ECOMMERCE.LIST.SHOPS',
    //             link: '/ecommerce/shops',
    //             parentId: 13
    //         },
    //         {
    //             id: 21,
    //             label: 'MENUITEMS.ECOMMERCE.LIST.ADDPRODUCT',
    //             link: '/ecommerce/add-product',
    //             parentId: 13
    //         },
    //     ]
    // },
 
];

