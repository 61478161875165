import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


interface StatusOption {
  id: number;
  name: string;
  color: string;
}



@Component({
  selector: 'app-service',
  templateUrl: './service.component.html',
  styleUrls: ['./service.component.scss']
})
export class ServiceComponent {

  cities = [
    { id: 1, name: 'New York' },
    { id: 2, name: 'Los Angeles' },
    { id: 3, name: 'Chicago' },
    { id: 4, name: 'Houston' }
  ];

  selectedCity: number;




  statuses: StatusOption[] = [
    { id: 1, name: 'Todo', color: '#f8d7da' },
    { id: 2, name: 'In progress', color: '#ffc107' },
    { id: 3, name: 'Issues/Contact Customer', color: '#dc3545' },
    { id: 4, name: 'Completed', color: '#28a745' },
    { id: 5, name: 'Picked Up', color: '#17a2b8' }
  ];
  selectedStatus: StatusOption;
  breadCrumbItems: Array<{}>;

  // Collapse declare
  isCollapsed: boolean;
  public firstColleaps = false;
  public secondColleaps = false;
  public bothColleaps = false;
 



  ngOnInit() {
    this.breadCrumbItems = [{ label: 'UI Elements' }, { label: 'Tabs & Accordions', active: true }];

    // Collapse value
    this.isCollapsed = false;
  }
  tableData = [
    { name: 'Mark Williams', Id: 365, ServiceRequestedBy: 'Layaway/Hold', Description: 'Hold his skis until he gets in to pick them up. VOLKL TIGER SHARK SKIS', RequestedBy: 'Monte', Status: 'Todo', Priority: 'Medium', Phone: '7894561230', PaidStatus: 'Paid', AmountDue: '$43', Gear: 'Used Burton',Location: 'Cashiers Desk', Created: '5/17/2024  8:09pm', },
    { name: 'John Doe', Id: 2, ServiceRequestedBy: 'Layaway/Hold', Description: 'Hold his skis until he gets in to pick them up. VOLKL TIGER SHARK SKIS', RequestedBy: 'Tim', Status: 'Todo', Priority: 'High', Phone: '9638520741', PaidStatus: 'Paid', AmountDue: '$43', Gear: 'Used Burton',Location: 'Cashiers Desk', Created: '5/17/2024  8:09pm', },
    { name: 'Henry ', Id: 3, ServiceRequestedBy: 'Layaway/Hold', Description: 'Hold his skis until he gets in to pick them up. VOLKL TIGER SHARK SKIS', RequestedBy: 'Monte', Status: 'Todo', Priority: 'Medium', Phone: '7894561230', PaidStatus: 'Needs Payment', AmountDue: '$240', Gear: 'Used Burton',Location: 'Cashiers Desk', Created: '5/17/2024  8:09pm', },
    { name: 'Mark Williams', Id: 365, ServiceRequestedBy: 'Layaway/Hold', Description: 'Hold his skis until he gets in to pick them up. VOLKL TIGER SHARK SKIS', RequestedBy: 'David', Status: 'Todo', Priority: 'Low', Phone: '9868584828', PaidStatus: 'Paid', AmountDue: '$80', Gear: 'Used Burton',Location: 'Cashiers Desk', Created: '5/17/2024  8:09pm', },
    { name: 'Messi ', Id: 3, ServiceRequestedBy: 'Layaway/Hold', Description: 'Hold his skis until he gets in to pick them up. VOLKL TIGER SHARK SKIS', RequestedBy: 'Monte', Status: 'Todo', Priority: 'High', Phone: '7894561230', PaidStatus: 'Paid', AmountDue: '$43', Gear: 'Used Burton',Location: 'Cashiers Desk', Created: '5/17/2024  8:09pm', },// Add more table data as needed
  ];
  
 
  constructor(private modalService: NgbModal) { 
  
  }
  /**
   * Open modal
   * @param content modal content
   */
  openModal(content: any) {
    this.modalService.open(content);
  }
    /**
   * Open extra large modal
   * @param exlargeModal extra large modal data
   */
  extraLarge(exlargeModal: any) {
    this.modalService.open(exlargeModal, { size: 'xl', centered: true });
  }

  
}
